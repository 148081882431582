#loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 100%;
	z-index: 2;
	margin-top: 30px;
}
.error-text-hide {
	display: none;
}
.error-text {
	display: block;
	color: red;
}

.error-input-hide {
	border: 1px solid #cd0000;
}

.error-input {
	border: 1px solid red;
}

.modal-error {
	width: 100%;
	margin-top: 50px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.modal-error .modal-close .close-x:before,
.close-x:after {
	position: absolute;
	right: 25px;
	content: " ";
	height: 23px;
	width: 5px;
	background-color: #cd0000;
}

.modal-error .modal-close .close-x:before {
	transform: rotate(45deg);
}

.modal-error .modal-close .close-x:after {
	transform: rotate(-45deg);
}

/*modal-close END*/
.modal-error .body {
	font-weight: 300;
	padding-top: 60px;
	text-align: center;
}

.modal-error .body img {
	width: 77px;
}

.modal-error .body p {
	padding: 10px 0;
}

.modal-error .body .title {
	font-size: 26px;
	line-height: 31px;
}

/*body END*/
.modal-error .button {
	width: 150px;
	height: 150px;
	background-image: url("https://assets.wilsonelectronics.com/m/68a7fa22d029b0b3/original/attention_icon-min.png");
	background-repeat: no-repeat;
}
/*modal-error END*/

@media only screen and (min-width: 768px) {
	.modal-error .body {
		font-size: 17px;
		line-height: 26px;
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
	}

	.modal-error .body .title {
		font-size: 36px;
		line-height: 43px;
	}
}

.page {
	background: #e9e9e9;
}

.installation-body {
	margin: 0 auto;
	max-width: 1600px;
	background: #ffffff;
}

.purchase {
	font-family: "Roboto-Light", helvetica, Arial, sans-serif;
	max-width: 250px;
	margin-left: auto;
	margin-right: auto;
	font-size: 22px;
	margin-top: 35px;
	font-weight: 100;
	line-height: 31px;
}

.purchase .purchase-body {
	height: 100px;
}

.purchase .purchase-body button {
	display: inline-block;
}
/*purchase END*/

.onTech-bg {
	margin-top: 50px;
}

.onTech-bg img {
	margin-bottom: 20px;
}

.onTech-bg .onTech-logo {
	width: 110px;
}

.onTech-bg .onTech-rep {
	margin-left: -22px;
	height: 100%;
}

.onTech-bg .onTech-rep img {
	height: 100%;
}

/*onTech-rep END*/
.onTech-bg p {
	font-family: "Roboto-Regular", helvetica, Arial, sans-serif;
	text-align: left;
	font-size: 14px;
	line-height: 22px;
}
/*.onTech-bg END*/

hr {
	width: 100%;
	border: 0;
	height: 1px;
	background: #000;
	opacity: 0.2;
}

.how-to-find {
	margin-top: 35px;
}

.how-to-find section {
	font-family: "Roboto-Light", helvetica, Arial, sans-serif;
	font-size: 22px;
	line-height: 40px;
	font-weight: 100;
	white-space: nowrap;
}

.how-to-find img {
	margin: 15px 0px 30px 0;
	width: 330px;
}

/*.how-to-find END*/
.info {
	font-family: "Roboto-Regular", helvetica, Arial, sans-serif;
	margin-top: 30px;
	text-align: left;
	font-size: 14px;
	line-height: 22px;
}

.icontainer {
	text-align: center;
	margin: 50px 22px;
	color: #515151;
}

.icontainer .code-input button {
	margin-top: 27px;
}
.icontainer .code-input {
	margin-top: 10px;
}

.icontainer button {
	background: #cd0000;
	color: white;
	text-transform: uppercase;
	font-size: 15px;
	letter-spacing: 0.5px;
	height: 50px;
	width: 126px;
	border: 1px solid #cd0000;
	cursor: pointer;
}

.icontainer input {
	font-size: 15px;
	line-height: 22px;
	color: #2b2b2b;
	box-sizing: border-box;
	height: 50px;
	width: 250px;
	text-indent: 15px;
}
/*.icontainer END*/

html {
	overflow-x: hidden;
	line-height: 1.15;
	overflow-x: hidden;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	font-family: "Roboto-Light", helvetica, Arial, sans-serif;
	margin: 0;
}

.new-line {
	display: block;
}

.header-home {
	color: #515151;
	line-height: 31px;
	font-weight: 100;
	font-size: 26px;
	text-align: center;
	background-color: #cacaca;
	height: 270px;
}

.header-home .bold {
	white-space: nowrap;
	font-weight: 600;
}

.header-home img {
	height: 24px;
	width: 121px;
	margin-bottom: 30px;
}

.header-home .h-body {
	margin-left: auto;
	margin-right: auto;
	display: block;
	line-height: 32px;
}

.header-home .header-contents {
	padding: 50px 10px 10px 10px;
}

/*header END*/
.non-mobile {
	display: none;
}

@media only screen and (min-width: 768px) {
	.non-mobile {
		display: block;
	}

	.purchase {
		max-width: 100%;
		margin: 40px 30px 20px 30px;
	}

	.purchase .purchase-body {
		line-height: 29px;
		display: grid;
		grid-template-areas: "left right";
		grid-template-columns: 55% 100px;
		grid-gap: 20px;
		text-align: right;
	}

	.purchase .purchase-body button {
		margin-top: 8px;
		width: 180px;
		height: 50px;
	}

	.purchase .purchase-body .left span {
		white-space: nowrap;
	}

	.purchase .purchase-body .left {
		grid-area: left;
	}

	.purchase .purchase-body .right {
		grid-area: right;
	}

	/*.purchase-body END*/
	/*.purchase END*/
	.non-mobile-new-line {
		display: block;
	}

	.onTech-bg {
		text-align: left;
		background-image: url("https://assets.wilsonelectronics.com/m/1ee3098dbf65812a/original/lifestyle_001-min.png");
		background-repeat: no-repeat;
		background-size: cover;
		height: 304px;
	}

	.onTech-bg .onTech {
		padding: 34px 82px;
	}

	.onTech-bg .onTech-logo {
		width: 210px;
	}

	.onTech-bg p {
		font-size: 17px;
		line-height: 26px;
		font-family: "Roboto-Regular", helvetica, Arial, sans-serif;
		width: 350px;
	}

	/*.onTech-bg END*/
	hr.non-mobile {
		margin: 60px auto 60px auto;
		width: 50%;
	}

	.how-to-find {
		margin-top: 35px;
	}

	.how-to-find img {
		margin-top: 10px;
		width: 605px;
	}

	.how-to-find section {
		font-size: 29px;
	}

	/*.how-to-find END*/
	.icontainer .inputs {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
	}
	.icontainer {
		margin: 250px 0px 0px 0px;
	}

	.icontainer .enter-code b {
		height: 28px;
	}

	.icontainer .enter-code .code-input {
		/*display: inline;*/
		padding: 5px;
	}

	/*.enter-code END*/
	.icontainer .info {
		font-size: 17px;
		line-height: 26px;
		margin-top: 50px;
		width: 450px;
		margin-left: auto;
		margin-right: auto;
	}

	/*.icontainer END*/
	.mobile {
		display: none;
	}

	.header-home {
		line-height: 31px;
		background-image: url("https://assets.wilsonelectronics.com/m/46daacd7e0bd2a14/original/header_ls_001-min.png");
		background-repeat: no-repeat;
		background-position-x: 58%;
		background-size: cover;
		height: 350px;
		text-align: left;
	}

	.header-home .non-mobile {
		display: inline;
	}

	.header-home .header-contents {
		padding: 41px 30px;
	}

	.header-home .header-contents .new-line-mobile {
		display: inline;
	}

	.header-home .header-contents .pipe {
		border-left: 1px solid #ccc;
	}

	.header-home .header-contents img {
		width: 210px;
		height: 42px;
	}

	/*.header-contents END*/
	/*header END*/
	.product-image {
		position: relative;
	}

	.product-image .home-complete-img {
		position: absolute;
		height: 410px;
		top: -160px;
		left: calc(50% - 140px);
		margin-left: -244px;
	}

	/*.product-image END*/
}

/*@media only screen and (min-width: 768px) END */

@media only screen and (min-width: 1240px) {
	.purchase {
		font-size: 29px;
	}

	.purchase .purchase-body {
		grid-template-columns: 75% 100px;
	}

	.purchase .left .new-line {
		display: inline-grid;
		margin-top: 15px;
	}

	/*.purchase END*/
	hr.non-mobile {
		display: none;
	}

	.how-to-find {
		display: inline-grid;
		margin-top: 500px;
	}

	.how-to-find section {
		font-size: 29px;
		height: 54px;
	}

	.how-to-find img {
		width: 735px;
	}

	/*.how-to-find END*/
	.onTech-bg {
		height: 397px;
	}

	.onTech-bg img {
		margin: 40px 0 40px 0;
	}

	.onTech-bg p {
		width: 500px;
	}

	/*.onTech-bg END*/
	.header-home {
		background-color: #e8e7e5;
		height: 484px;
	}

	.header-home .header-contents {
		padding: 80px 86px;
	}

	.header-home .header-contents .h-body {
		font-size: 36px;
		line-height: 43px;
	}

	.header-home .header-contents .h-body .home-complete-img {
		position: absolute;
		width: 720px;
		height: 588px;
		top: 178px;
		left: -60px;
	}

	/*.h-body END*/
	/*.header-contents END*/
	/*header END*/
	.icontainer {
		position: relative;
	}

	.icontainer .redeem {
		height: 300px;
		position: absolute;
		right: 4%;
		top: -200px;
	}

	/*.icontainer END*/
	.product-image {
		position: relative;
	}

	.product-image .home-complete-img {
		position: absolute;
		height: 588px;
		top: -160px;
		left: 0;
		margin-left: 0;
	}
	/*.product-image END*/
}

/*@media only screen and (min-width: 1240px) END*/

@media only screen and (min-width: 1400px) {
	.non-mobile-new-line {
		display: inline;
	}
	.icontainer .redeem {
		right: 5%;
	}

	.product-image {
		position: relative;
	}

	.product-image .home-complete-img {
		left: 25%;
	}
}
